<template>
    <div class="supermarket-furniture">
         <generic-bg-color></generic-bg-color>
        <!-- Options -->
        <boxes-services
            :full_top="true"
            :data_box="data_box">
        </boxes-services>
        <!-- Góndolas -->
        <gallery-generic :gallery_data="gondolas">
        </gallery-generic>
        <!-- Archivadores -->
        <gallery-generic :gallery_data="cabinets">
        </gallery-generic>
        <!-- Lockers -->
        <gallery-generic :gallery_data="lockers">
        </gallery-generic>
        <!-- Especiales -->
        <gallery-generic :gallery_data="specials">
        </gallery-generic>
        
        <!-- FOOTER -->
        <main-footer></main-footer>
    </div>
</template>

<script>
export default {
    data() {
        return {
           data_box: {
                title: 'Muebles de supermercado',
                description: `
                    Tenemos más de 35 años ayudando a a las empresas del país a mejorar su productividad 
                    con sistemas de almacenamiento eficientes.
                `,
                box_1: {
                    title: 'Góndolas',
                    // url_image: 'mobiliario-de-supermercado/gondolas/productos/isla/isla_1.jpg',
                    url_image: 'mobiliario-de-supermercado/gondolas/gondola_1.jpg',
                    name_link: 'gondolas'
                },
                box_2: {
                    title: 'Especiales',
                    url_image: 'mobiliario-de-supermercado/muebles-especiales.jpg',
                    name_link: 'special-furniture'
                },
                box_3: {    
                    title: 'Archivadores',
                    url_image: 'mobiliario-de-supermercado/lockers.jpg',
                    name_link: 'cabinets'
                }
            },
            gondolas: {
                title: 'Góndolas para Supermercado',
                description: `
                    <b><i>Eficientes para tus puntos de venta</i></b><br>
                    En una tienda o supermercado es clave saber dividir los espacios, por ello, las góndolas
                    son especialmente creadas para acomodar productos en autoservicio u otros comercios de manera eficiente,
                    se caracterizan por ser de grandes superficies y poseer divisiones que permiten un mejor alcance del
                    producto para el consumidor.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'gondolas',
                slides: [
                    'mobiliario-de-supermercado/gondolas/gondola_isla.jpg',
                    'mobiliario-de-supermercado/gondolas/escalonada_1.jpg',
                    'mobiliario-de-supermercado/gondolas/gondola_pared.jpg',
                    'mobiliario-de-supermercado/gondolas/canasta_ruedas_1.jpg',
                    'mobiliario-de-supermercado/gondolas/punta_gondola.jpg'
                ]
            },
            cabinets: {
               title: 'Archivadores',
                description: `
                    <b><i>Mejoran la distribución y el espacio</i></b><br>
                    Los archivadores para oficina son indispensables, pues una buena organización y eficacia, 
                    promueven un trabajo más proactivo. Son ideales para planificar eventos, organizar facturas 
                    o llevar a cabo todos los proyectos.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'cabinets',
                slides: [
                    'mobiliario-de-supermercado/archivadores/archivadores_1.jpg',
                    'mobiliario-de-supermercado/archivadores/archivadores_2.jpg'
                ]                
            },
            lockers: {
               title: 'Lockers',
                description: `
                    <b><i>Brindan un espacio personal</i></b><br>
                    Son muy útiles para escuelas, empresas o gimnasios, brindan un espacio personal dándole 
                    a cada individuo privacidad a sus pertenencias. Cuentan con cerradura o porta candado y 
                    poseen ventanillas de ventilación, algunos vienen equipados con barras para colgar y otro 
                    tipo de accesorios internos.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'lockers',
                slides: [
                    'mobiliario-de-supermercado/lockers/locker_1.jpg',
                    'mobiliario-de-supermercado/lockers/locker_2.jpg'
                ]                     
            },
            specials: {
                title: 'Muebles Especiales',
                description: `
                    <b><i>Brindan un espacio personal</i></b><br>
                    Son muy útiles para escuelas, empresas o gimnasios, brindan un espacio personal dándole 
                    a cada individuo privacidad a sus pertenencias. Cuentan con cerradura o porta candado y 
                    poseen ventanillas de ventilación, algunos vienen equipados con barras para colgar y otro 
                    tipo de accesorios internos.
                `,
                button_text: 'VER PRODUCTOS',
                button_link: 'special-furniture',
                slides: [
                    'mobiliario-de-supermercado/muebles-especiales/especial_1.jpg'
                ]                
            }
        }
    },
    mounted() {
       document.title = 'Muebles de Supermercado';
    },
}
</script>
